import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import FindADentist from '../components/find-dentist'
import SEO from '../components/seo'

const Procedure = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Lumineers Procedure | Lumineers vs Traditional Porcelain Veneers"
          description="Lumineers are designed to deliver a beautiful smile transformation with a simple, painless procedure unlike traditional porcelain veneers that may require aggressive drilling and shots."
        />
        <Container>
          <Row>
            <Col md="6">
              <div className="mt-5">
                <h1 className="text-center">
                  Transform your smile in just a few easy visits
                </h1>
                <p>
                  While every oral health situation is unique and your
                  experience may vary, most Lumineers<sup>®</sup> procedures are
                  completed in just a few easy visits. Your dentist may
                  recommend additional dental procedures to complement your
                  Lumineers smile makeover. After your initial smile design
                  consultation, your dentist will take impressions of your upper
                  and lower teeth and send the impressions to the dental lab
                  where your Lumineers are fabricated. Your Lumineers will be
                  placed during your final appointment. Lumineers are placed by
                  dentists trained in painless minimally invasive techniques
                  that typically require no shots or aggressive drilling. The
                  result is an immediate, natural-looking and long-lasting smile
                  transformation. You won’t stop smiling!
                </p>
                <h3>Lumineers can be prescribed to correct:</h3>
                <ul>
                  <li>Stained or Discolored Teeth</li>
                  <li>Chipped Teeth</li>
                  <li>Spacing and Gaps</li>
                  <li>Misaligned or Crooked Teeth</li>
                  <li>Small or Misshapen Teeth</li>
                  <li>Aging Dental Work</li>
                  <li>Undesired Smile Esthetics</li>
                </ul>
              </div>
            </Col>
            <Col md="6">
              <div className="mt-5">
                <StaticImage
                  src="../images/Woman-Mirror-1-Lumineers-800x800.jpg"
                  alt="Woman looking in mirror"
                  placeholder="none"
                  layout="fullWidth"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <h1 className="text-center mt-5 mb-5">
                The Lumineers painless minimally invasive porcelain veneer
                procedure
              </h1>
              <div class="embed-responsive embed-responsive-16by9 mb-5">
                <iframe
                  src="https://www.youtube.com/embed/GQz0axnJtLs"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Lumineers Alanta"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mb-5">
          <Row>
            <Col md="12">
              <h2>Lumineers vs Traditional Porcelain Veneers</h2>
              <p>
                Lumineers are designed to deliver a beautiful smile
                transformation with a simple, painless procedure. Lumineers are
                custom made exclusively by the DenMat Lab in Lompoc, California,
                USA, by technicians that specialize in crafting esthetic dental
                veneers. Lumineers are digitally designed to be ultra-thin
                (about as thin as a contact lens), so they can be applied
                seamlessly over your teeth to deliver a natural-looking smile.
                Traditional porcelain veneers are generally thicker, and usually
                require more aggressive and irreversible removal of healthy
                tooth structure. The extensive preparation required for
                traditional porcelain veneers frequently requires anesthesia
                shots to minimize sensitivity and pain, and typically involves
                placement of acrylic temporaries in between appointments. The
                minimally invasive Lumineers technique typically eliminates the
                need for shots or temporaries, so you will still look and feel
                great while your Lumineers are artistically crafted in the lab.
              </p>
            </Col>
          </Row>
        </Container>
        <FindADentist></FindADentist>
      </Layout>
    </>
  )
}

export default Procedure
